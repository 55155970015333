import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import "react-toastify/dist/ReactToastify.css";
import Box from "../Box";
import { ToastContainer } from "react-toastify";
export var TOAST_Z_INDEX = 9999;
export var Container = styled(Box).withConfig({
  displayName: "styles__Container",
  componentId: "sc-oqrk23-0"
})(["display:flex;align-items:center;gap:", ";font-family:", ";", " position:relative;padding:", ";"], function (_ref) {
  var theme = _ref.theme;
  return theme.space[350];
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontFamily;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.typography[200];
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.space[350];
});
export var ToastRoot = styled(ToastContainer).attrs({
  toastClassName: "Toastify-toast-overrides",
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  position: "bottom-right"
}).withConfig({
  displayName: "styles__ToastRoot",
  componentId: "sc-oqrk23-1"
})(["--toastify-z-index:", ";--toastify-toast-offset:", ";--toastify-toast-width:360px;--toastify-toast-min-height:48px;--toastify-toast-max-height:70vh;--toastify-toast-bd-radius:", ";--toastify-toast-background:", ";--toastify-toast-bottom:0;padding:0;.Toastify-toast-overrides{background:", ";color:", ";box-shadow:", ";padding:0;margin-bottom:var(--toastify-toast-offset);}.Toastify__toast-body{padding:0;margin:0;}@media only screen and (max-width:480px){.Toastify-container-overrides{min-width:initial !important;}}@keyframes SproutToast__zoom-in{from{opacity:0;transform:scale3d(0.3,0.3,0.3);}50%{opacity:1;}}@keyframes SproutToast__zoom-out{from{opacity:1;}50%{opacity:0;transform:scale3d(0.3,0.3,0.3) translate3d(0,var(--y),0);}to{opacity:0;}}.SproutToast__zoom-in{animation:SproutToast__zoom-in ", " ", " both;}.SproutToast__zoom-out{animation:SproutToast__zoom-out ", " ", " both;}@keyframes SproutToast__none-in{from{opacity:0;}to{opacity:1;}}@keyframes SproutToast__none-out{from{opacity:1;}to{opacity:0;}}.SproutToast__none-in{animation:SproutToast__none-in 0s both;}.SproutToast__none-out{animation:SproutToast__none-out 0s both;}"], TOAST_Z_INDEX, function (_ref5) {
  var theme = _ref5.theme;
  return theme.space[400];
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.radii[400];
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.container.background.base;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.container.background.base;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.text.body;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.shadows.low;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.duration.medium;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.easing.ease_out;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.duration.slow;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.easing.ease_in;
});