import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import Text from "@sproutsocial/seeds-react-text";

const Container = styled.span`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;

  ${COMMON}
`;

Container.displayName = "Radio";

export const Input = styled.input`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  appearance: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border: 1px solid ${(props) => props.theme.colors.form.border.base};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.form.background.base};
  transition: border-color ${(props) => props.theme.duration.fast}
      ${(props) => props.theme.easing.ease_in},
    background-color ${(props) => props.theme.duration.fast}
      ${(props) => props.theme.easing.ease_in};

  &:focus {
    ${focusRing}
  }
`;

Input.displayName = "Input";

export const InputWrapper = styled.span<{
  checked?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  width: ${(props) => props.theme.space[400]};
  height: ${(props) => props.theme.space[400]};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => props.disabled && disabled}

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: 0;
    transition: opacity ${(props) => props.theme.duration.fast}
      ${(props) => props.theme.easing.ease_in};
    z-index: 1;
    pointer-events: none;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  ${(props) =>
    !props.checked &&
    css`
      &:hover,
      &:focus {
        ${Input} {
          border-color: ${props.theme.colors.form.border.base};
          background-color: ${props.theme.colors.form.background.base};
        }

        &:before {
          opacity: ${props.disabled ? 0 : 1};
          background-color: ${props.theme.colors.form.background.base};
        }
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      ${Input} {
        border-color: ${props.theme.colors.form.border.selected};
        background-color: ${props.theme.colors.form.background.selected};
      }

      &:before {
        opacity: 1;
        background-color: ${props.theme.colors.form.background.base};
      }
    `}
`;

InputWrapper.displayName = "InputWrapper";

export const LabelText = styled(Text)<{ disabled?: boolean }>`
  margin-left: ${(props) => props.theme.space[300]};
  font-size: ${(props) => props.theme.typography[200].fontSize};
  line-height: 1;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.colors.text.headline};

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

LabelText.displayName = "LabelText";

export default Container;
